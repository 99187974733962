@font-face {
  font-family: NotoSansJP;
  font-weight: bold;
  src: url("fonts/NotoSansJP-Bold.otf") format("opentype");
}
@font-face {
  font-family: NotoSansJP;
  font-weight: regular;
  src: url("fonts/NotoSansJP-Regular.otf") format("opentype");
}

* {
  font-family: NotoSansJP;
}
:root {
  --deep-color: #5b98d6;
  --sub-color: #b0ddfc;
  --sub-color-hover: #daf0ff;
  --light-color: #c0e7fc;
  --extra-light-color: #d4eefc;
  --almost-white-color: #efefef;
  --font-color: white;
  --font-color-back: var(--deep-color);
  --transparent-deep-color: #5b98d6aa;
  --transparent-sub-color: #b0ddfcaa;
  --extra-light-color: #d4eefc70;
  --white-trans: #ffffff66;
}
button {
  background-color: var(--deep-color) !important;
  color: white !important;
  font-size: 20px !important;
  font-weight: normal !important;
}

html,
body {
  background-color: var(--deep-color);
  height: 100%;
  min-width: 300px;
}
body {
  margin: 0;
  color: var(--font-color);
  /* font-family: Roboto, "Helvetica Neue", sans-serif;*/
}
.logo {
  background-image: url("./resources/gfx/HNS_plain.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px;
}

.mai-menu-class {
  max-width: 999px;
  background-color: var(--transparent-sub-color) !important;
  backdrop-filter: blur(2px);
}
.mai-menu-class,
.mai-menu-class button {
  transition: all linear 0.2s;
  color: var(--deep-color) !important;
  /*text-shadow: 0px 0px 3px var(--deep-color);*/
  width: 250px;
  font-size: 20px;
}

.mai-menu-class button {
  height: 40px; /*
  padding-top: 10px;
  padding-bottom: 10px;*/
  text-align: right;
  background-color: var(--white-trans) !important;
  font-weight: 500 !important;
}

.mai-menu-class button:hover {
  background-color: var(--transparent-light-color) !important;
  color: white;
}

.mai-menu-class .mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: var(--deep-color);
}

.coo-menu-class,
.coo-menu-class button {
  background-color: var(--sub-color) !important;
  color: white;
}

.coo-menu-class button:hover {
  background-color: var(--sub-color-hover) !important;
  color: #545454 !important;
}

.coo-menu-class .mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: var(--deep-color);
}

.coo-menu-class button:hover.mat-menu-item .mat-icon-no-color,
.mai-menu-class button:hover .mat-menu-item-submenu-trigger::after {
  color: #545454 !important;
}
.loading-banner {
  z-index: -1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  user-select: none;
}

@media (min-width: 683px) {
  .system-menu-btn.div-icon-btn {
    width: 50px !important;
    flex-shrink: 0;
  }
}
